import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './store'
import './index.css';
import './bootstrap.min.css'
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'dotenv/config';
// import dotenv from 'dotenv';
// const { SHOP_TITLE, SHOP_NAME, REACT_APP_MY_ENV } = process.env;
// require('dotenv').config();
// dotenv.config();
require('dotenv').config();
console.log(process.env);
console.log(process.env.SHOP_TITLE);
console.log(process.env.SHOP_NAME);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
